import { Observable } from 'rxjs';
import {
  ParamsPersonProductGetAllDto,
  ResponsePersonProductGetAllDto,
  PersonProductPageQueryParamsDto,
  PersonProductPageDto,
  BodyPersonProductRevisionDto,
  ResponsePersonProductRevisionDto,
} from '../dtos/person-product.dto';

export abstract class PersonProductRepository {
  abstract getPage(
    params: PersonProductPageQueryParamsDto
  ): Observable<PersonProductPageDto>;

  abstract getAll(
    params: ParamsPersonProductGetAllDto,
    headers?: Record<string, string>
  ): Observable<ResponsePersonProductGetAllDto>;

  abstract endDate(body: any): Observable<any>;

  abstract revision(
    body: BodyPersonProductRevisionDto
  ): Observable<ResponsePersonProductRevisionDto>;

  abstract updateRevision(
    body: BodyPersonProductRevisionDto
  ): Observable<ResponsePersonProductRevisionDto>;

  abstract stateMassive(body: any): Observable<any>;

  abstract reasign(body: any): Observable<any>;

  abstract showRevision(id: any): Observable<any>;

  abstract state(body: any): Observable<any>;
}
